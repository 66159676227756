@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

@layer components {
    .bluebtn {
      @apply text-white uppercase w-[307px] rounded-[8px] text-[16px] py-[22px] border-0 bg-blue duration-300 hover:bg-darkblue active:bg-darkblue;
    }
    .transbtn {
      @apply text-white uppercase w-[303px] rounded-[8px] text-[16px] py-[21px] border-2 border-lightgrey border-solid bg-[#D9D9D90A] backdrop-blur duration-300 hover:bg-blue hover:border-blue active:bg-darkblue active:border-darkblue;
    }
    .bigTitle {
      @apply uppercase text-[40px] font-extralight leading-[56px];
    }
    .littleTitle {
      @apply text-[26px] font-normal leading-[36px];
    }
    .par {
      @apply text-[22px] font-light leading-[29px];
    }
}
